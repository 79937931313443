// Import Image for ImageCard_Collapse
import nytjob from '../../../assets/Images/image-nytjob.png';
import syg from '../../../assets/Images/image-syg.png';

// TempData
import TempDataNavBar from '../TempData_NavBar';
import TempDataMegaMenu from '../TempData_MegaMenu';
import TempDataFooter from '../TempData_footer';
import * as ColorGuide from '../../../cssGuides/colorGuide';

// Page Data
const Data = {
  pageName: 'ImageCard Test Page',
  pageTemplate: 'Template3',
  pageData: {
    nav: TempDataNavBar,
    megaMenu: TempDataMegaMenu,
    footer: TempDataFooter,
    level_1_Id: 'Page_1',
    pageTitle: 'HTML render test page',
    pageDescription: 'Tester component - HTML render',
    bannerImage: null, // accepts null
    bannerContent: '<h1>Banner text accepts HTML</h1>', // accepts null
    bottomContent: [
      {
        componentName: 'BlockArea',
        componentData: {
          backgroundColor: ColorGuide.aqua,
        },
        componentChildren: [
          {
            componentName: 'Columns',
            componentData: {
              columns: 3,
              gap: '40',
              minWidth: '768px',
            },
            componentChildren: [
              {
                componentName: 'ImageCard',
                componentData: {
                  id: 1,
                  image: syg,
                  imageAltText: 'Sygdom',
                  url: '',
                  manchet: 'Det skal du vide',
                  text: 'Hvis du bliver syg',
                },
              },
              {
                componentName: 'ImageCard',
                componentData: {
                  id: 2,
                  image: '',
                  imageAltText: 'Pension',
                  url: '/item1',
                  manchet: 'Optimer din udbetaling',
                  text: 'Snart på pension?',
                },
              },
              {
                componentName: 'ImageCard',
                componentData: {
                  id: 3,
                  image: nytjob,
                  imageAltText: 'Nyt job',
                  url: 'http://www.google.com',
                  manchet: 'Dine muligheder',
                  text: 'Nyt job eller fratrædelse?',
                },
              },
            ],
          },
        ],
      },
    ],
    desktopTools: {}, // accepts null
  },
  pageContent: [
    {
      componentName: 'Columns',
      componentData: {
        columns: 3,
        gap: '20',
        minWidth: '768px',
      },
      componentChildren: [
        {
          componentName: 'ImageCard',
          componentData: {
            id: 1,
            image: syg,
            imageAltText: 'Sygdom',
            url: '',
            manchet: 'Det skal du vide',
            text: 'Hvis du bliver syg',
          },
        },
        {
          componentName: 'ImageCard',
          componentData: {
            id: 2,
            image: '',
            imageAltText: 'Pension',
            url: '/item2',
            manchet: 'Optimer din udbetaling',
            text: 'Snart på pension?',
          },
        },
        {
          componentName: 'ImageCard',
          componentData: {
            id: 3,
            image: nytjob,
            imageAltText: 'Nyt job',
            url: '/testThree',
            manchet: 'Dine muligheder',
            text: 'Nyt job?',
          },
        },
      ],
    },
  ],
};

export default Data;
